import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./domainSearch.scss";
import ChildOfId from "../../hooks/dataFetchers/childIdOf";
import DomainMinting from "../../hooks/dataSenders/mintDomain";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Countdown from "react-countdown";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import axios from "axios";
import { async } from "q";
import { useRef } from "react";
import Cart from "../cart/Cart";
import namer from "@rstacruz/startup-name-generator";
import DomainMintingPrice from "../../hooks/dataSenders/mintPrice";
function DomainSearch({
  domainProp,
  domainName,
  setLoader,
  setMinted,
  minted,
  setCartState,
  cartState,
}) {
  const [inputVal, setInputVal] = useState(domainName);
  const [domainType, setDomainType] = useState(parseInt(domainProp));
  const [show, setShow] = useState(false);
  const { domainMintingMethod } = DomainMinting();
  const [domainAvail, setDomainAvail] = useState(false);
  const [realPrice, setRealPrice] = useState(0);
  const handleClose = () => setShow(false);
  const [sugNameLoad, setSugNameLoad] = useState(false);
  const [show1, setShow1] = useState(false);
  const [mintRes, setMintRes] = useState();
  const [availabilityArray, setAvailabilityArray] = useState([]);
  const [toastDesign] = useState({
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  const [pBid, setPBid] = useState();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const { Mainfunct } = ChildOfId();
  let { account } = useWeb3React();
  const [domainAvailMeth, setDomainAvailMeth] = useState();
  const [sugNames, SetSugNames] = useState([]);
  const [filterArray, setFilterArray] = useState(sugNames);
  const [normalDomArr, setNormalDomArr] = useState(sugNames);
  const [showTimer, setShowTimer] = useState(false);
  const [timerTime, setTimerTime] = useState(0);
  const [suggArr, setSugges] = useState();
  const { domainMintingPriceMethod } = DomainMintingPrice();
  const domainTypebyProp = [
    "TOMI",
    "COM",
    "TOMI",
    "COM",
    "TOMI",
    "COM",
    "TOMI",
    "COM",
    "TOMI",
    "COM",
    "TOMI",
    "COM",
  ];
  const inputRef = useRef(null);
  const [cartArr, setCartArr] = useState([]);
  const [cartStorageData, setCartStorageData] = useState([]);
  // console.log("cartttttt", normalDomArr, realPrice);
  const getSearchData = async (domain, type) => {
    if (!domain || !type) {
      return false;
    }

    try {
      setLoader(true);
      const data = {
        query: `query MyQuery {
          auctionCreateds(orderBy: startTime, orderDirection: desc, where: {label: "${domain}", tld: "${type}"}) {
            expiryTime
            id
          }
        }`,
        variables: {},
      };

      const response = await axios.post(Environment.marketplaceGraph, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const minted = response?.data?.data?.auctionCreateds;
      // setLoader(false);

      if (minted?.length > 0) {
        return minted;
      } else {
        return false;
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const domainSearchFunc = async () => {
    try {
      setDomainAvail(false);
      const res = await Mainfunct(inputVal, domainTypebyProp[domainType].toLocaleLowerCase());
      setDomainAvailMeth(res);
      setDomainAvail(res);
    } catch (error) {
      setDomainAvail(false);
      toast.error("Domain Unavailable", toastDesign);
    } finally {
      setLoader(false);
    }
  };

  const mintdom = async () => {
    try {
      setShow(false);
      setShow1(true);

      const isMinted = await domainMintingMethod(inputVal, true);
      if (isMinted) {
        setTimerTime(65000);
        setShowTimer(true);
        const mintRes = await domainMintingMethod(inputVal, false);

        toast.success("Mint Successful", toastDesign);
        toast.info("Minted Domains take time to show", toastDesign);

        setShow1(false);
        setShow2(true);
        setMinted(!minted);
        setMintRes(mintRes);
      }
    } catch (error) {
      toast.error("Minting Failed", toastDesign);
      setShow(false);
      setShow1(false);
      setShow2(false);
    }
  };

  const inputVAlue = (e) => {
    const value = e.replace(/\s/g, "");
    const pattern = /^[A-Za-z0-9-]*$/;

    if (pattern.test(value)) {
      const lowercaseValue = value.toLowerCase();
      setInputVal(lowercaseValue);
      // Call other functions or perform additional actions if needed
      // domainSearchFunc();
    }
  };
  // const suggestedDomains = async () => {
  //   if (inputVal.endsWith("-")) {
  //     toast.info("Domain Should Not End With Special Character", toastDesign);
  //   } else if (inputVal.length < 3) {
  //     toast.info("Character Length should be greater than 2", toastDesign);
  //   } else {
  //     try {
  //       const res2 = [inputVal, inputVal, ...(namer(inputVal)?.slice(0, 10))];
  //       setSugges(res2)
  //       setSugNameLoad(true);
  //       setLoader(true);

  //       const requests = domainTypebyProp.map((domainType, id) =>
  //         Promise.all([
  //           Mainfunct(res2[id]?.toLowerCase(), domainType.toLocaleLowerCase()),
  //           getSearchData(res2[id]?.toLowerCase(), domainType.toLocaleLowerCase()),
  //         ])
  //       );
  //       setLoader(true);
  //       const results = await Promise.all(requests);

  //       const dumArray = results.map(([res]) => res);
  //       const dumArray2 = results.map(([, res2]) => res2);
  //       console.log('dumArray', dumArray);
  //       setPBid(dumArray2);
  //       SetSugNames(dumArray);
  //       setFilterArray(dumArray);
  //       setNormalDomArr(dumArray);
  //       setLoader(false);
  //     } catch (error) {
  //       console.error("Error occurred:", error);
  //       setLoader(false);
  //     } finally {
  //       setSugNameLoad(false);
  //     }
  //   }
  // };
  const suggestedDomains = async () => {
    if (inputVal.endsWith("-")) {
      toast.info("Domain Should Not End With Special Character", toastDesign);
    } else if (inputVal.length < 3) {
      toast.info("Character Length should be greater than 2", toastDesign);
    } else {
      try {
        const res2 = [inputVal, inputVal, ...namer(inputVal)?.slice(0, 10)];
        setSugges(res2);
        setSugNameLoad(true);
        setLoader(true);

        const requests = domainTypebyProp.map((domainType, id) =>
          Promise.all([
            Mainfunct(res2[id]?.toLowerCase(), domainType.toLowerCase()),
            getSearchData(res2[id]?.toLowerCase(), domainType.toLowerCase()),
          ])
        );
        setLoader(true);
        const results = await Promise.all(requests);

        const dumArray = results.map(([res]) => res);
        const dumArray2 = results.map(([, res2]) => res2);
        console.log("dumArray", dumArray);
        setPBid(dumArray2);
        SetSugNames(dumArray);
        setFilterArray(dumArray);
        setNormalDomArr(dumArray);
        setLoader(false);

        // Combine `notAvaliable` function call
        const availabilityArray = dumArray.map(async (item, id) => {
          const domainType = domainTypebyProp[id]?.toLowerCase();
          const suggestion = res2[id]?.toLowerCase(); // Use res2 here since suggArr might not be defined at this point
          return await notAvaliable(domainType, suggestion);
        });

        // Store availabilityArray in state or variable as needed
        setAvailabilityArray(availabilityArray);
      } catch (error) {
        console.error("Error occurred:", error);
        setLoader(false);
      } finally {
        setSugNameLoad(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      suggestedDomains();
    }
  };
  const cartFun = async (id) => {
    // console.log('ddddddddddd', id)
  };
  // console.log('cartArr', cartArr, cartState)
  const addToCart = async (name, tld) => {
    let domain = { name, tld };
    // setCartArr((pre) => {
    //   return [...pre, domain]

    // })
    const res = await domainMintingPriceMethod(name, tld);
    let a = parseInt(res);
    let b = parseFloat(a * realPrice).toFixed(3);
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "USD",
          value: "100",
          items: [
            {
              item_name: { domain },
              USD: "$100",
              quantity: 1,
            },
          ],
        },
      });
      console.log("cartttttt1111eve", window.dataLayer);
      console.log(`GTM event 'Add to cart' triggered `);
    } else {
      console.error("Google Tag Manager dataLayer is not available");
    }

    setCartArr([domain]);
  };
  const mergeArrays = (arr1, arr2) => {
    // Combine both arrays into a single array
    const combinedArray = [...arr1, ...arr2];

    // Create an object to track unique objects based on name and tld
    const uniqueObjects = {};

    // Filter out duplicates and keep only the first occurrence of each unique combination
    const mergedArray = combinedArray.filter((obj) => {
      const key = obj.name + obj.tld;
      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        return true;
      }
      return false;
    });

    return mergedArray;
  };
  const removeItem = async (lowerCaseSuggArr, domainType) => {
    let res = cartStorageData?.filter((item) => {
      return `${item?.name}${item?.tld}` !== `${lowerCaseSuggArr}${domainType}`;
    });
    localStorage.setItem("cart", JSON.stringify(res));
    setCartState(!cartState);
    // console.log('filter', res)
  };

  const Price = async(() => {
    axios
      .get(
        "https://pro-api.coingecko.com/api/v3/simple/price?ids=tominet&x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false"
      )
      .then((res) => {
        setRealPrice(res?.data?.tominet?.usd);
        // setEthUsd(res.data.USD);
      });
  });
  useEffect(() => {
    if (cartArr?.length !== 0) {
      let cartArrSt = localStorage.getItem("cart");
      cartArrSt = cartArrSt ? JSON.parse(cartArrSt) : []; // Parse only if cartArrSt is not null

      const mergedArray = mergeArrays(cartArrSt, cartArr);
      localStorage.setItem("cart", JSON.stringify(mergedArray));
      setCartState(!cartState);
    }
  }, [cartArr]);
  let notAvaliable = async function (tld, name) {
    console.log(name, tld, "availability");

    try {
      setLoader(true);
      const data = {
        query: `query MyQuery {
         auctionCreateds(where: {label: "${name}", tld: "${tld}"}) {
    expiryTime
  }
        }`,
        variables: {},
      };

      const response = await axios.post(Environment.marketplaceGraph, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const expiryTime = response?.data?.data?.auctionCreateds[0]?.expiryTime; // 1715773475
      const currentTime = Math.floor(Date.now() / 1000);
      setLoader(false);
      console.log(expiryTime < currentTime, expiryTime, currentTime, "availability");
      return expiryTime < currentTime;
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  useEffect(() => {
    Price();
    if (inputVal) {
      domainSearchFunc();
      suggestedDomains();
    }
  }, [domainType, mintRes, account]);
  useEffect(() => {
    let cartArr = localStorage.getItem("cart");
    cartArr = JSON.parse(cartArr);
    setCartStorageData(cartArr);
  }, [cartState !== undefined && cartState]);
  console.log("cartStorageData", normalDomArr);
  return (
    <>
      <section className="domain-search">
        <h1>
          Search <span className="domainss">Domains</span>
        </h1>
        <div className="input-field">
          <div className="mysearchinput">
            <img src="\assets\search-normal.svg" alt="img" className="img-fluid  input-icon" />
            <input
              onKeyDown={handleKeyDown}
              ref={inputRef}
              onChange={(e) => {
                inputVAlue(e.target.value?.split(".")[0]);
                setDomainAvail(false);
                setNormalDomArr([]);
              }}
              value={inputVal}
              type="text"
              placeholder="domain.tomi"
            />
            <img
              onClick={() => setInputVal("")}
              src="\searchResults\add.png"
              className="img-fluid crosImg2 cPointer"
              alt=""
            />
          </div>
          <button
            disabled={domainType < 0 || inputVal == ""}
            onClick={() => {
              suggestedDomains();
            }}
            class="btn-searchbar"
            type="button"
          >
            <img
              src="\assets\newlanding-assets\search-icon-mbl.svg"
              alt="img"
              className="img-fluid d-none showsearch-icon"
            />
            <span>Search</span>
          </button>
        </div>
        <div className="top_domainss">
          {console.log(normalDomArr, domainTypebyProp, suggArr, "fafafasfasfdas")}
          {inputVal &&
            normalDomArr?.map((item, id) => {
              const isAuction = !item;

              const domainType = domainTypebyProp[id]?.toLocaleLowerCase();
              const lowerCaseSuggArr = suggArr[id]?.toLowerCase();
              const linkToPath = `/progressbar/${domainType}/${lowerCaseSuggArr}`;
              const availability = availabilityArray[id]; // Get availability from domain
              // console.log("availability", availability);
              const stored = cartStorageData?.some(
                (item) => item?.name === lowerCaseSuggArr && item?.tld === domainType
              );
              return (
                <div>
                  <div className={`availableDomain${isAuction ? " mb-0 pb-0" : ""}`} key={id}>
                    {/* <div className="row"> */}
                    {/* <div className="col-sm-9 my-auto"> */}
                    <div className=" domainMain">
                      <p>
                        <span>{lowerCaseSuggArr}</span>.{domainType}
                      </p>
                      <div
                        className={` availtick ${
                          isAuction ? (availability ? " bg-danger" : " bg-warning") : ""
                        }`}
                      >
                        <h5 className={isAuction ? (availability ? " reed" : " heee") : ""}>
                          {isAuction ? (availability ? "Unavailable" : "In Auction") : "Available"}
                        </h5>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-sm-3"> */}
                    <div className="d-flex  domainRightHeartDiv">
                      {isAuction ? (
                        availability ? null : (
                          <Link
                            to={`/place/${pBid[id][0]?.id}`}
                            className="cartButton text-center w-50 cartButton2"
                          >
                            Place a bid
                          </Link>
                        )
                      ) : (
                        <>
                          {stored ? (
                            <div
                              onClick={() => removeItem(lowerCaseSuggArr, domainType)}
                              className="add-cart-btn cPointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                              >
                                <path
                                  d="M14.4003 14.7917H5.96908C5.18533 14.7917 4.43324 14.4417 3.90283 13.8333C3.37241 13.225 3.10325 12.4083 3.16659 11.5833L3.82367 3.28333C3.84742 3.025 3.76033 2.77501 3.59408 2.58334C3.42783 2.39168 3.19825 2.29167 2.95284 2.29167H1.58325C1.25867 2.29167 0.989502 2.00833 0.989502 1.66667C0.989502 1.325 1.25867 1.04167 1.58325 1.04167H2.96076C3.53868 1.04167 4.08491 1.3 4.47283 1.74167C4.68658 1.99167 4.84492 2.28333 4.932 2.60833H14.8199C15.6195 2.60833 16.3558 2.94167 16.8941 3.54167C17.4245 4.15 17.6937 4.94167 17.6303 5.78333L17.2028 12.0333C17.1158 13.5583 15.8491 14.7917 14.4003 14.7917ZM4.97159 3.85L4.35409 11.6833C4.3145 12.1667 4.46492 12.625 4.77367 12.9833C5.08242 13.3417 5.50991 13.5333 5.96908 13.5333H14.4003C15.2237 13.5333 15.9678 12.8 16.0312 11.9333L16.4587 5.68334C16.4903 5.19168 16.3399 4.72501 16.0312 4.38334C15.7224 4.03334 15.2949 3.84166 14.8278 3.84166H4.97159V3.85Z"
                                  fill="#FF0083"
                                />
                                <path
                                  d="M12.8646 18.9583C11.9937 18.9583 11.2812 18.2083 11.2812 17.2917C11.2812 16.375 11.9937 15.625 12.8646 15.625C13.7354 15.625 14.4479 16.375 14.4479 17.2917C14.4479 18.2083 13.7354 18.9583 12.8646 18.9583ZM12.8646 16.875C12.6429 16.875 12.4688 17.0583 12.4688 17.2917C12.4688 17.525 12.6429 17.7083 12.8646 17.7083C13.0863 17.7083 13.2604 17.525 13.2604 17.2917C13.2604 17.0583 13.0863 16.875 12.8646 16.875Z"
                                  fill="#FF0083"
                                />
                                <path
                                  d="M6.53133 18.9583C5.6605 18.9583 4.948 18.2083 4.948 17.2917C4.948 16.375 5.6605 15.625 6.53133 15.625C7.40216 15.625 8.11466 16.375 8.11466 17.2917C8.11466 18.2083 7.40216 18.9583 6.53133 18.9583ZM6.53133 16.875C6.30966 16.875 6.1355 17.0583 6.1355 17.2917C6.1355 17.525 6.30966 17.7083 6.53133 17.7083C6.753 17.7083 6.92716 17.525 6.92716 17.2917C6.92716 17.0583 6.753 16.875 6.53133 16.875Z"
                                  fill="#FF0083"
                                />
                                <path
                                  d="M16.625 7.29167H7.125C6.80042 7.29167 6.53125 7.00833 6.53125 6.66667C6.53125 6.325 6.80042 6.04167 7.125 6.04167H16.625C16.9496 6.04167 17.2188 6.325 17.2188 6.66667C17.2188 7.00833 16.9496 7.29167 16.625 7.29167Z"
                                  fill="#FF0083"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div
                              onClick={() => addToCart(lowerCaseSuggArr, domainType)}
                              className="add-cart-btn cPointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                              >
                                <path
                                  d="M14.4003 14.7917H5.96908C5.18533 14.7917 4.43324 14.4417 3.90283 13.8333C3.37241 13.225 3.10325 12.4083 3.16659 11.5833L3.82367 3.28333C3.84742 3.025 3.76033 2.77501 3.59408 2.58334C3.42783 2.39168 3.19825 2.29167 2.95284 2.29167H1.58325C1.25867 2.29167 0.989502 2.00833 0.989502 1.66667C0.989502 1.325 1.25867 1.04167 1.58325 1.04167H2.96076C3.53868 1.04167 4.08491 1.3 4.47283 1.74167C4.68658 1.99167 4.84492 2.28333 4.932 2.60833H14.8199C15.6195 2.60833 16.3558 2.94167 16.8941 3.54167C17.4245 4.15 17.6937 4.94167 17.6303 5.78333L17.2028 12.0333C17.1158 13.5583 15.8491 14.7917 14.4003 14.7917ZM4.97159 3.85L4.35409 11.6833C4.3145 12.1667 4.46492 12.625 4.77367 12.9833C5.08242 13.3417 5.50991 13.5333 5.96908 13.5333H14.4003C15.2237 13.5333 15.9678 12.8 16.0312 11.9333L16.4587 5.68334C16.4903 5.19168 16.3399 4.72501 16.0312 4.38334C15.7224 4.03334 15.2949 3.84166 14.8278 3.84166H4.97159V3.85Z"
                                  fill="#FF0083"
                                />
                                <path
                                  d="M12.8646 18.9583C11.9937 18.9583 11.2812 18.2083 11.2812 17.2917C11.2812 16.375 11.9937 15.625 12.8646 15.625C13.7354 15.625 14.4479 16.375 14.4479 17.2917C14.4479 18.2083 13.7354 18.9583 12.8646 18.9583ZM12.8646 16.875C12.6429 16.875 12.4688 17.0583 12.4688 17.2917C12.4688 17.525 12.6429 17.7083 12.8646 17.7083C13.0863 17.7083 13.2604 17.525 13.2604 17.2917C13.2604 17.0583 13.0863 16.875 12.8646 16.875Z"
                                  fill="#FF0083"
                                />
                                <path
                                  d="M6.53133 18.9583C5.6605 18.9583 4.948 18.2083 4.948 17.2917C4.948 16.375 5.6605 15.625 6.53133 15.625C7.40216 15.625 8.11466 16.375 8.11466 17.2917C8.11466 18.2083 7.40216 18.9583 6.53133 18.9583ZM6.53133 16.875C6.30966 16.875 6.1355 17.0583 6.1355 17.2917C6.1355 17.525 6.30966 17.7083 6.53133 17.7083C6.753 17.7083 6.92716 17.525 6.92716 17.2917C6.92716 17.0583 6.753 16.875 6.53133 16.875Z"
                                  fill="#FF0083"
                                />
                                <path
                                  d="M16.625 7.29167H7.125C6.80042 7.29167 6.53125 7.00833 6.53125 6.66667C6.53125 6.325 6.80042 6.04167 7.125 6.04167H16.625C16.9496 6.04167 17.2188 6.325 17.2188 6.66667C17.2188 7.00833 16.9496 7.29167 16.625 7.29167Z"
                                  fill="#FF0083"
                                />
                              </svg>
                            </div>
                          )}
                          {/* <Cart path="\cart\addcart.svg" bool={false} onClick={() => cartFun(pBid[id][0]?.id)} /> */}
                          <Link to={linkToPath} className="cartButton text-center w-50">
                            {/* {true ? "Buy Now" : "Already Registered"} */}
                            Buy Now
                          </Link>
                        </>
                      )}
                    </div>
                    {/* </div> */}
                  </div>
                  <div className="botttoms_cards">
                    <div>
                      <p>Highest Bid</p>
                      <h1>
                        {isAuction
                          ? availability
                            ? "-"
                            : "-"
                          : `${parseInt(100 / realPrice).toFixed(2)} tomi`}
                        {/* {availability ? "-" : `${parseInt(100 / realPrice).toFixed(2)} tomi`}{" "} */}
                      </h1>
                    </div>
                    <div>
                      <p>Estimated value </p>

                      <h1>{isAuction ? (availability ? "-" : "-") : "100 USDT"} </h1>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
      <section className="modal-mint1">
        <Modal show={show} onHide={handleClose} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>Register & Mint</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a className="register-btn" onClick={mintdom}>
              Register & Mint
            </a>
          </Modal.Body>
        </Modal>
      </section>
      <section className="modal-mint">
        <Modal show={show1} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>Minting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inner-content-minting">
              <img src="\assets\laoding.png" alt="img" className="img-fluid rotate" />
              <p>Your NFT is minting</p>
              {showTimer ? (
                <p>
                  Estimate Time{" "}
                  <span className="common">
                    {" "}
                    <Countdown date={Date.now() + parseInt(timerTime)} />
                  </span>{" "}
                  Minute
                </p>
              ) : (
                <p>
                  Request To <span className="common">Register</span>{" "}
                </p>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <section className="modal-mint">
        <Modal show={show2} onHide={handleClose2} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>MintING</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inner-content-minting">
              <img src="\assets\tick-circle.png" alt="img" className="img-fluid" />
              <p>Your NFT is minted successfully</p>
              <h6>And placed on marketplace for an auction for 24h period</h6>
              <Link
                to="/domainSearhResult/active/mm"
                onClick={() => {
                  handleClose2();
                  handleClose();
                }}
              >
                <a className="register-btn"> Go Checkout My Domain</a>
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default DomainSearch;
